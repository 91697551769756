// extracted by mini-css-extract-plugin
export var bg = "page-module--bg--HIDLL";
export var logo = "page-module--logo--GbHt6";
export var shopPage = "page-module--shopPage--JxeHz";
export var shopPage__banner = "page-module--shopPage__banner--KZZmr";
export var shopPage__banner__desktop = "page-module--shopPage__banner__desktop--P4vpJ";
export var shopPage__banner__mobile = "page-module--shopPage__banner__mobile--Bd9qf";
export var shopPage__filters = "page-module--shopPage__filters--wg2ed";
export var shopPage__header = "page-module--shopPage__header--3NsjK";
export var shopPage__header__left = "page-module--shopPage__header__left--qNNpE";
export var shopPage__header__left__bg = "page-module--shopPage__header__left__bg--Q7AB2";
export var shopPage__header__left__content = "page-module--shopPage__header__left__content--DndMO";
export var shopPage__header__right = "page-module--shopPage__header__right--y8BS8";
export var shopPage__intro = "page-module--shopPage__intro--2PKGS";
export var shopPage__items = "page-module--shopPage__items---+hqf";
export var shopPage__load_more = "page-module--shopPage__load_more--wXt7A";
export var shopPage__partners = "page-module--shopPage__partners--c0DoO";
export var shopPage__partners__item = "page-module--shopPage__partners__item--zXUQE";
export var shopPage__partners__item__img = "page-module--shopPage__partners__item__img--VqEWt";
export var shopPage__partners__item__txt = "page-module--shopPage__partners__item__txt--t1HFd";
export var shopPage__people = "page-module--shopPage__people--2++Bj";